* {
    box-sizing: border-box;
  }
  
  a {
    cursor: pointer;
  }
  

  
  img {
    max-width: 100%;
    border-radius: 15px;
  }
 
  
  header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 50px;
    align-items: center;
  }
  
  header a {
    text-decoration: none;
    color: inherit;
  }
  
  header a.logo {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  header nav {
    display: flex;
    gap: 24px;
  }
  
  div.post {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  @media screen and (min-width: 700px) {
    div.post {
      grid-template-columns: 0.9fr 1.1fr;
    }
  }
  
  div.post div.texts h2 {
    margin: 0;
    font-size: 2rem;
  }
  
  div.post div.texts a {
    text-decoration: none;
    color: inherit;
  }
  
  div.post p.info {
    margin: 24px 0;
    color: #888;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    gap: 10px;
  }
  
  div.post p.info a.author {
    color: #aaa;
  }
  
  div.post p.summary {
    margin: 10px 0;
    line-height: 1.8rem;
  }
  
  form.login,
  form.register {
    max-width: 400px;
    margin: 0 auto;
  }
  
  input {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    padding: 5px 7px;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  button {
    cursor: pointer;
    width: 100%;
    display: block;
    background-color: #555;
    border: 0;
    color: #fff;
    border-radius: 5px;
    padding: 7px 0;
  }
  
  form.login h1,
  form.register h1 {
    text-align: center;
  }
  
  div.post-page div.image {
    max-height: 300px;
    display: flex;
    overflow: hidden;
  }
  
  div.post-page div.image img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
  }
  
  div.post-page a {
    color: #333;
    text-decoration: underline;
  }
  
  div.post-page h1 {
    text-align: center;
    margin: 10px 0 5px;
    font-size: calc(16px+1vw);
  }
  
  div.post-page time {
    text-align: center;
    display: block;
    font-size: 1rem;
    color: #aaa;
    margin: 10px 0;
  }
  
  div.post-page div.author {
    text-align: center;
    margin-bottom: 20px;
    font-size: 0.7rem;
    font-weight: bold;
  }
  
  div.content p {
    line-height: 1.7rem;
    margin: 30px 0;
  }
  
  div.content li {
    margin-bottom: 10px;
  }
  
  div.edit-row {
    text-align: center;
    margin-bottom: 20px;
  }
  
  div.post-page a.edit-btn {
    background-color: #333;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: #fff;
    padding: 15px 30px;
    border-radius: 5px;
    text-decoration: none;
  }
  
  a svg {
    height: 20px;
  }
  
  .basic-multi-select{
    width: 50%;
  }

  .ql-editor{
    height: 250px;
    max-height: 250px;
    overflow: auto;
  }